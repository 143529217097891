<template lang="pug">
div
  q-dialog(
    ref="form",
    v-model="modal.form",
    @hide="closeDialog",
    transition-show="slide-left",
    transition-hide="slide-right"
  )
    component(
      :is="getForm(grid)",
      @row-is-active="changeRowActive",
      @close-form="closeForm",
      :parentData="{ grid: grid, row: callback_row, callback_params: callback_params }"
    )

  q-dialog(
    ref="show",
    v-model="modal.show",
    @hide="closeDialog",
    transition-show="slide-left",
    transition-hide="slide-right"
  )
    component(
      ref="show-component",
      :is="getShow(grid)",
      @row-is-active="changeRowActive",
      @close-show="closeShow",
      @refresh-table="refreshTableRows",
      @open-form="openForm",
      :parentData="{ row: callback_row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, show_tab: show_tab, fromDashboard: fromDashboard }"
    )

  q-dialog(ref="files", v-model="modal.files")
    existing-files-area(:parentData="{ files: files }")

  q-dialog(ref="export", v-model="modal.export")
    export-excel-form(
      @close-export="closeExportForm",
      :parentData="{ export_attr: export_attr, grid: grid, path: path }"
    )

  q-dialog(ref="import", v-model="modal.import")
    import-excel-form(
      @close-import="closeImportForm",
      @reload-data="reloadData",
      :parentData="{ import_attr: import_attr, grid: grid, path: path }"
    )

  q-dialog(ref="verifyCheckins", v-model="modal.verifyCheckins")
    verify-checkins-form(
      @close="closeVerifyCheckinsForm",
      @reload-data="reloadData",
      :parentData="{ verify_checkins_attr: verify_checkins_attr, grid: grid, path: path }"
    )

  q-dialog(ref="qrPrintListForm", v-model="modal.qrPrintListForm")
    print-qr-list-form(
      @close="closePrintQrListForm",
      @reload-data="reloadData",
      :parentData="{ formAttr: print_qr_list_form_attr, grid: grid, path: path }"
    )
</template>

<script>
import { useEmitter } from "@/services/useEmitter";
import crud_events from "../crud_events/index";
import custom_forms from "../custom_forms/index";
import existingFilesArea from "../helpers/existingFilesArea";
import exportExcelForm from "../forms/exportExcelForm";
import importExcelForm from "../forms/importExcelForm";
import verifyCheckinsForm from "../forms/verifyCheckinsForm";
import printQrListForm from "../forms/printQrListForm";

export default {
  components: {
    exportExcelForm,
    crud_events,
    custom_forms,
    existingFilesArea,
    importExcelForm,
    verifyCheckinsForm,
    printQrListForm,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      transition_key: this.parentData.transition_key,
      fromDashboard: this.parentData.fromDashboard,
      show_tab: "",

      modal: {
        form: false,
        show: false,
        files: false,
        export: false,
        import: false,
        verifyCheckins: false,
        qrPrintListForm: false,
      },

      form_type: null,
      callback_row: {},
      callback_params: {},
      export_attr: {},
      import_attr: {},
      verify_checkins_attr: {},
      print_qr_list_form_attr: {},
      files: [],
      emitter: useEmitter(),
    };
  },

  watch: {
    parentData(newVal, oldVal) {
      if (newVal) {
        this.grid = newVal.grid;
        this.path = newVal.path;
        this.grid_key = newVal.grid_key;
        this.transition_key = newVal.transition_key;
        this.fromDashboard = newVal.fromDashboard;
      }
    },
  },

  methods: {
    reloadData() {
      this.$emit("reload-data");
    },

    changeRowActive(val) {
      this.$emit("row-is-active", val);
    },

    openFilesModal(files) {
      this.files = files;
      this.modal.files = true;
    },

    openExportForm(attr) {
      this.export_attr = attr;
      this.modal.export = true;
    },

    closeExportForm() {
      this.export_attr = {};
      this.$refs.export.hide();
    },

    openImportForm(attr) {
      this.import_attr = attr;
      this.modal.import = true;
    },

    closeImportForm() {
      this.import_attr = {};
      this.$refs.import.hide();
    },

    openVerifyCheckinsForm(attr) {
      this.verify_checkins_attr = attr;
      this.modal.verifyCheckins = true;
    },

    closeVerifyCheckinsForm() {
      this.verify_checkins_attr = {};
      this.$refs.verifyCheckins.hide();
    },

    openPrintQrListForm(attr) {
      this.print_qr_list_form_attr = attr;
      this.modal.qrPrintListForm = true;
    },

    closePrintQrListForm() {
      this.print_qr_list_form_attr = {};
      this.$refs.qrPrintListForm.hide();
    },

    openShow(row) {
      if (Object.keys(crud_events["show"]).includes(this.grid)) {
        if (row) {
          if (row.show_tab) {
            this.show_tab = row.show_tab;
            row.show_tab = "";
          } else {
            this.show_tab = "";
          }
          this.callback_row = row;
          this.$emit("set-callback-row", this.callback_row);
        }

        this.modal.show = true;
      }
    },

    getShow(grid) {
      return crud_events["show"][grid];
    },

    closeShow(row) {
      this.$refs.show.hide();
    },
    closeDialog() {
      if (!this.modal.show) {
        this.callback_row = {};
        this.$emit("set-callback-row", this.callback_row);
        this.$emit("row-is-active", false);
        this.$root.$emit("reset-show-form");
      }
    },
    openForm(data) {
      if (data.row) {
        this.callback_row = data.row;
        this.$emit("set-callback-row", this.callback_row);
      }
      if (data.callback_params) {
        this.callback_params = data.callback_params;
      }
      this.form_type = data.form_type;

      if (data.grid) {
        this.grid = data.grid;
      }
      this.modal.form = true;
    },

    getForm(grid) {
      if (this.form_type) {
        if (["add", "edit", "show"].includes(this.form_type)) {
          return crud_events[this.form_type][grid];
        } else {
          return custom_forms[this.form_type][grid];
        }
      }
    },

    closeForm(data) {
      if (!this.modal.show) {
        this.callback_row = {};
        this.$emit("set-callback-row", this.callback_row);
        this.$emit("row-is-active", false);
      }

      this.$refs.form.hide();
      if (data.data) {
        this.refreshTableRows({ attr: data.data, action: data.method });
      }
    },

    refreshTableRows(row) {
      if (this.forceRefreshList(row)) {
        this.$root.$emit("filters-form-reset");
        return; // don't insert item on refreshing list
      }

      if (this.modal.show) {
        if (this.$refs["show-component"].refreshRow) {
          this.$refs["show-component"].refreshRow(row); // old components
        } else {
          this.emitter.emit("reset-show-modal-component", row); // composition api, show component must watch this event
        }
      }
      this.$emit("refresh-table", row);
    },

    forceRefreshList(row) {
      return row.attr["force_refresh_list"] == true;
    },
  },
};
</script>
